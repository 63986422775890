<template>
  <div class="page-wrap login-bg-dark">
    <div class="session-form-hold">
      <div class="logo-wrapper">
        <img height="30" src="@/assets/images/logo-light.svg" alt="" />
      </div>

      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title class="text-center">Admin Login</v-card-title>
        <v-card-text class="text-center">
          <v-form>
            <v-text-field
              label="Email"
              v-model="email"
              :rules="emailRules"
              :disabled="isLoading"
              required
            />

            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              :counter="10"
              :rules="passwordRules"
              :disabled="isLoading"
              v-model="password"
              @click:append="show = !show"
            ></v-text-field>

            <v-btn
              :loading="isLoading"
              class="mb-5 mt-7"
              @click="formSubmit"
              block
              dark
            >
              <!-- <v-icon left>mdi-login</v-icon> -->
              Sign In</v-btn
            >

            <!-- <div class="d-flex justify-center flex-wrap">
              <v-btn text small color="primary" class="mb-2"
                >Forgot Password</v-btn
              >
            </div> -->
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "login",
  metaInfo: {
    title: "Sign In",
  },
  data() {
    return {
      show: false,
      password: "Password",
      email: "",
      password: "",
      loading: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required"
      ],
    };
  },
  created() {},
  computed: {
    ...mapGetters("auth", {
      isLoading: "isLoading"
    }),
  },
  methods: {
    ...mapActions("auth", {
      attemptLogin: "attemptLogin"
    }),

    formSubmit() {
      this.attemptLogin({ email: this.email, password: this.password });
    }
  },
};
</script>
<style lang="scss" scoped>
.logo-wrapper {
  text-align: center;
  margin-bottom: 30px;
}

.page-wrap {
  background-color: #f1f1f1 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login-bg-dark {
  background: #242939 !important;
}
</style>
